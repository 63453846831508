<template>
  <div class="home">
    <Head></Head>
    <div class="container common">
      <div class="banner-wrap">
        <TabBar class="tabber"></TabBar>
        <img src="../assets/image/banner.png" class="banner" />
        <div class="info-wrap">
          <img src="../assets/image/log@2.png" class="log" />
          <p class="name">湘安云教交通安全学习平台</p>
          <p class="introduce">更多人选择的驾驶员学习平台</p>
          <p class="introduce">
            提供道路运输企业的负责人、管理人员的安全培训，企业从业人员的安全培训、岗前培训等
          </p>
        </div>
      </div>

      <ul class="list flex space-around">
        <li class="item">
          <img src="../assets/image/pic1.png" class="pic" />
          <p class="title">实名认证</p>
          <p class="sub">
            报名人员人脸与公安系统身份证进行实名认证，确保报名的学员为本人
          </p>
        </li>
        <li class="item">
          <img src="../assets/image/pic2.png" class="pic" />
          <p class="title">人脸比对</p>
          <p class="sub">
            学习全过程中，将学院人脸与报名采集人脸经行特征比对，确保学习的人和报名的人是同一人。
          </p>
        </li>
        <li class="item">
          <img src="../assets/image/pic3.png" class="pic" />
          <p class="title">活体认证</p>
          <p class="sub">
            进行人脸比对的环节，会进行实时的动态活体验证，杜绝照片、视频欺骗，确保真人学习。
          </p>
        </li>
      </ul>
      <div class="data-wrap flex align-items space-around">
        <div class="flex flex-direction align-items justify-content">
          <p class="num">5000+</p>
          <p class="type">学员(名)</p>
        </div>
        <div class="flex flex-direction align-items justify-content">
          <p class="num">100+</p>
          <p class="type">公司(家)</p>
        </div>
        <div class="flex flex-direction align-items justify-content">
          <p class="num">50+</p>
          <p class="type">员工(位)</p>
        </div>
        <div class="flex flex-direction align-items justify-content">
          <p class="num">3</p>
          <p class="type">成立时间(年)</p>
        </div>
      </div>

      <div class="head-title">全新产品开放，等你加入</div>
      <div class="sub-title">
        我们拥有年轻、积极向上的交通安全行业专家团队、完整的课程结构、稳定的系统平台。期待与您的共谋发展。
      </div>
      <div class="btn btn-click unselect">立即加盟</div>
    </div>
    <Basal></Basal>
  </div>
</template>

<script>
import Head from "../components/head/head";
import TabBar from "../components/tabbar/tabbar";
import Basal from "../components/basal/basal";

export default {
  name: "Home",
  components: {
    Head,
    TabBar,
    Basal,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .banner-wrap {
    position: relative;
    width: 100%;
    height: 784px;
    margin-bottom: 115px;
    .banner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: inherit;
      z-index: 0;
    }
    .info-wrap {
      position: relative;
      z-index: 2;
      text-align: center;
      .log {
        display: block;
        width: 160px;
        height: 160px;
        margin: 109px auto 35px;
      }
      .name {
        font-size: 72px;
        font-weight: normal;
        color: #fcf9f7;
        margin-bottom: 35px;
      }
      .introduce {
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
        line-height: 54px;
        margin: auto;
      }
    }
  }
  .head-title {
    position: relative;
    font-size: 61px;
    font-weight: bold;
    color: #3e7fe1;
    text-align: center;
    padding-bottom: 35px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 441px;
      height: 1px;
      background: #749eec;
    }
  }
  .sub-title {
    font-size: 31px;
    font-weight: 400;
    text-align: center;
    color: #000000;
    margin-top: 35px;
    margin-bottom: 140px;
  }
  .list {
    .item {
      text-align: center;
      .pic {
        width: 445px;
        height: 568px;
      }
      .title {
        font-size: 31px;
        font-weight: normal;
        color: #000000;
        margin: 46px 0 27px;
      }
      .sub {
        width: 354px;
        margin: auto;
        font-size: 20px;
        font-weight: normal;
        color: #000000;
      }
    }
  }
  .data-wrap {
    width: 100%;
    height: 340px;
    padding: 0 60px;
    margin-top: 130px;
    margin-bottom: 103px;
    background: #001540;
    .num {
      font-size: 77px;
      font-weight: 800;
      color: #ffffff;
    }
    .type {
      text-align: center;
      font-size: 26px;
      font-weight: normal;
      color: #ffffff;
      margin-top: 45px;
    }
  }
  .btn {
    width: 304px;
    height: 78px;
    line-height: 78px;
    text-align: center;
    background: #759fec;
    border-radius: 39px;
    font-size: 33px;
    font-weight: 400;
    margin: 70px auto 80px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #5c90f1;
    }
  }
}
@media screen and (min-width: 1600px) and (max-width: 1920px) {
.pic {
    width: 400px !important;
    height: 511px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .introduce{
    max-width: 900px;
  }
  .pic {
    width: 363px !important;
    height: 461px !important;
  }
}
</style>
