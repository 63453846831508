<template>
  <div class="tabbar-wrap flex align-items unselect">
    <div class="item">主页</div>
    <div class="item">了解湘安</div>
    <div class="item">业务体系</div>
    <div class="item">新闻动态</div>
    <div class="item">联系我们</div>
  </div>
</template>

<script>
export default {
  name: "tabbar",
};
</script>

<style lang="scss" scoped>
.tabbar-wrap {
  position: relative;
  width: 100%;
  height: 77px;
  line-height: 77px;
  padding: 0 60px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  .item {
    height: inherit;
    font-size: 20px;
    font-weight: normal;
    color: #ffffff;
    padding: 0 30px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}
</style>