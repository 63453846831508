<template>
  <div class="head">
    <div class="logo common flex align-items">
      <img src="../../assets/image/log.png" class="logo" />
      <span>湖南湘安云教交通安全学习平台</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "head",
};
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  height: 120px;
  background: #fff;
  .logo{
    cursor: pointer;
  }
  .common {
    height: inherit;
    background: #fff;
    padding: 0 64px;
    .logo {
      width: 62px;
      height: 62px;
      margin-right: 10px;
    }
    span {
      font-size: 25px;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>