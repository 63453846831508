<template>
  <div class="footer">
    <div class="common flex space-between">
      <div>
        <p class="contact">联系我们</p>
        <p class="tel">商务合作：17872552600</p>
      </div>
      <div class="code-wrap">
        <img src="../../assets/image/code.png" class="code" />
        <p>微信公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "basal",
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 326px;
  background-color: #343030;
  .common {
    height: inherit;
    background-color: #343030;
    color: #fff;
    padding: 60px 200px 0 200px;
    .contact {
      font-size: 33px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .tel {
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      margin-bottom: 15px;
    }
    .code-wrap {
      text-align: center;
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      .code {
        width: 142px;
        height: 142px;
      }
    }
  }
}
</style>